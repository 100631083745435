<template>
  <div class="common-bg shop-manage">
    <van-nav-bar title="商品编辑" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit">
      <van-cell-group inset class="home-img">
        <div>店铺首页图片</div>
        <div class="df aic jcc">
          <van-uploader
            :after-read="afterRead"
            :before-read="beforeRead"
            :max-count="1"
            v-model="fileList"
          />
        </div>
      </van-cell-group>
      <van-cell-group inset>
        <van-field label="商户名称" v-model="name" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field label="商品价格" v-model="price" />
      </van-cell-group>
    </van-form>
    <van-button type="primary" size="normal" @click="onSubmit">保存</van-button>
  </div>
</template>

<script >
import { goodsManage, upload } from "@/utils/api.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      id: this.$route.query.id,
      name: this.$route.query.name,
      price: this.$route.query.price,
      fileList: [this.$route.query.img],
    };
  },
  created() {},

  methods: {
    onSubmit() {
      const fileList = this.img;
      goodsManage({
        id: this.id,
        trade_name: this.name,
        trade_price: this.price,
        shop_home_img: this.fileList,
      }).then(async (res) => {
        if (res.code === 200) {
          await Toast.success("修改成功");
          this.$router.push("/user/goodManage");
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    beforeRead(file) {
      if (
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png"
      ) {
        this.$toast("请上传jpg或png格式的图片");
        return false;
      }
      return true;
    },
    afterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-manage {
  ::v-deep .van-field__control {
    text-align: right;
  }

  .shop-des {
    padding: 20px;

    .van-field {
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }
  }

  .home-img {
    padding: 20px;

    .van-uploader {
      margin-top: 20px;
    }
  }
}

.van-cell-group {
  margin-bottom: 20px;
}

.id-card {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.van-button {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  display: inherit;
}
</style>
